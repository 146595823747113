import store from '@/store'
import {useRouter} from '@core/utils/utils'
import {useShopRemoteData} from "@/views/shop/useECommerceShop";

export const useEcommerce = () => {
    // eslint-disable-next-line arrow-body-style
    const addProductInWishlist = productId => {
        return store.dispatch('app-ecommerce/addProductInWishlist', {productId})
    }

    // eslint-disable-next-line arrow-body-style
    const removeProductFromWishlist = productId => {
        return store.dispatch('app-ecommerce/removeProductFromWishlist', {productId})
    }

    // eslint-disable-next-line arrow-body-style
    const addProductInCart = (productId, chosenSubscription = null) => {
        return store.dispatch('app-ecommerce/addProductInCart',
            {
                productId,
                chosenSubscription,
            })
    }

    // eslint-disable-next-line arrow-body-style
    const removeProductFromCart = productId => {
        return store.dispatch('app-ecommerce/removeProductFromCart', {productId})
    }

    return {
        addProductInWishlist,
        removeProductFromWishlist,
        addProductInCart,
        removeProductFromCart,
    }
}

export const useEcommerceUi = () => {
    const {router} = useRouter()

    const toggleProductInWishlist = product => {
        const {addProductInWishlist, removeProductFromWishlist} = useEcommerce()
        if (product.isInWishlist) {
            removeProductFromWishlist(product.id).then(() => {
                // eslint-disable-next-line no-param-reassign
                product.isInWishlist = false
            })
        } else {
            addProductInWishlist(product.id).then(() => {
                // eslint-disable-next-line no-param-reassign
                product.isInWishlist = true
            })
        }
    }

    const handleCartActionClick = (product, chosenSubscription = null) => {
        const {addProductInCart, removeProductFromCart} = useEcommerce()
        const {getCartToken} = useShopRemoteData()

        if (product.isInCart === true) {
            router.push({name: 'checkout'})
        } else {
            // if (product.subscription) {
            //    removeProductFromCart(product.id)
            // }
            addProductInCart(product.id, chosenSubscription).then(() => {
                // eslint-disable-next-line no-param-reassign
                product.isInCart = true

                // Update cart items count
                store.dispatch("app-ecommerce/fetchCartItems");
                router.push({name: 'checkout'})
            })
        }
    }

const handleWishlistCartActionClick = (product, removeProductFromWishlistUi) => {
    const {addProductInCart, removeProductFromWishlist} = useEcommerce()

    if (product.isInCart) {
        router.push({name: 'apps-e-commerce-checkout'})
    } else {
        addProductInCart(product.id)
            .then(() => {
                console.log('ADD')
                // eslint-disable-next-line no-param-reassign
                product.isInCart = true
                removeProductFromWishlist(product.id)

                // Update cart items count
                store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', store.state['app-ecommerce'].cartItemsCount + 1)
            })
            .then(() => {
                // eslint-disable-next-line no-param-reassign
                product.isInWishlist = false
                removeProductFromWishlistUi(product)
            })
    }
}
return {
    toggleProductInWishlist,
    handleCartActionClick,
    handleWishlistCartActionClick,
}
}
